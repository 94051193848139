<div class="content">
  <!-- slider row-->
  <app-new-slider></app-new-slider>

  <!-- category slider -->
  <app-category-slider></app-category-slider>

  <!-- best Pro Slider -->
  <app-taxonomy-offers
    [taxonomyId]="1"
    [title]="'common.best_selling' | translate"
  ></app-taxonomy-offers>

  <!-- ad content -->
  <app-ad-content
  [adImageOne]="'assets/images/ads/machineries.jpg'"
  [adImageTwo]="'assets/images/ads/hybrid-brahmas.png'"
  [adLinkOne]="'/products/category/machineries'"
  [adLinkTwo]="'/products/brahma-chicks/28'"
  ></app-ad-content>

  <!-- featured products -->
  <app-taxonomy-offers
    [taxonomyId]="2"
    [title]="'common.featured_products' | translate"
  ></app-taxonomy-offers>

  <!-- ad content -->
  <app-ad-content
  [adImageOne]="'assets/images/ads/2ND-FLYER-DUCKWEED.jpg'"
  [adImageTwo]="'assets/images/ads/2ND-LYER-AZOLLA.jpg'"
  [adLinkOne]="'/products/Duckweed/139'"
  [adLinkTwo]="'/products/Azolla/138'"
  ></app-ad-content>

  <!-- recommended products -->
  <app-taxonomy-offers
    [taxonomyId]="3"
    [title]="'common.recommended_products' | translate"
  ></app-taxonomy-offers>

  <!-- ad content -->
  <app-ad-content
  [adImageOne]="'assets/images/ads/C600.png'"
  [adImageTwo]="'assets/images/new/terena-f1-palm-seeds.png'"
  [adLinkOne]="'/products/broiler-chicks/1'"
  [adLinkTwo]="'/products/palm-seedling/109'"
  ></app-ad-content>

  <!-- latest blogs -->
  <app-latest-blogs></app-latest-blogs>

  <!-- download app -->
  <app-download-app></app-download-app>

  <!-- services -->
  <app-services></app-services>

  <!-- <section class="best-sell">
    <div class="best-pro slider-items-products container">
      <div class="new_title">
        <h2>{{ "home.new_in" | translate }}</h2>
        <p>&nbsp;</p>
      </div> -->
  <!-- <div
        *ngIf="!loading; else loadingDiv"
        id="best-seller"
        class="product-flexslider hidden-buttons"
      >
        <div
          class="slider-items slider-width-col4 products-grid owl-carousel owl-theme"
          style="opacity: 1; display: block"
        >
          <div class="owl-wrapper-outer">
            <div
              class="owl-wrapper"
              style="width: 100%; left: 0px; display: block"
            >
              <div
                class="owl-item"
                style="width: 310px"
                *ngFor="let offer of offers"
              >
                <div class="item">
                  <div class="item-inner">
                    <div class="item-img">
                      <div class="item-img-info">
                        <a
                          [routerLink]="[
                            'products',
                            offer.product.name.replace(' ', '-'),
                            offer.id
                          ]"
                          [attr.title]="offer.product.name"
                          class="product-image"
                        >
                          <img
                            [lazyLoad]="getImageSrc(offer)"
                            [defaultImage]="defaultImage"
                            [alt]="offer.product.name"
                        /></a>
                      </div>
                      <div class="add_cart" *ngIf="!IsProductInCart">
                        <button
                          class="button btn-cart"
                          type="button"
                          (click)="addProductToCart(offer)"
                        >
                          <span>{{ "common.add_to_card" | translate }}</span>
                        </button>
                      </div>
                    </div>
                    <div class="item-info">
                      <div class="info-inner">
                        <div class="item-title">
                          <a
                            [routerLink]="[
                              'products',
                              offer.product.name.replace(' ', '-'),
                              offer.id
                            ]"
                            [attr.title]="offer.product.name"
                          >
                            {{
                              currentLanguage == "fr" &&
                              offer.product.productNameFr != null &&
                              offer.product.productNameFr != ""
                                ? offer.product.productNameFr
                                : offer.product.name
                            }}
                          </a>
                        </div>
                        <div class="item-content">
                          <div class="item-price">
                            <div class="price-box">
                              <span class="regular-price">
                                <span class="price"
                                  >{{ getOfferPrice(offer) }}
                                  {{ offer.currency | uppercase }} /
                                  {{
                                    currentLanguage == "fr" &&
                                    offer.measurementunit.descriptionFr !=
                                      null &&
                                    offer.measurementunit.descriptionFr != ""
                                      ? offer.measurementunit.descriptionFr
                                      : offer.measurementunit.description
                                  }}</span
                                >
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
  <ng-template #loadingDiv>
    <div class="loader-box">
      <div class="lds-dual-ring"></div>
    </div>
  </ng-template>
  <!-- </div>
  </section> -->
</div>
